import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { useRef  } from "react"
import Layout from "../components/core/Layout"
import Navigation from "../components/core/Navigation"

import beyondSymbolWhite from "../images/common/symbol-beyond-white.svg"
import locationIcon from "../images/common/location-icon.svg"
import phoneIcon from "../images/common/phone-icon.svg"
import quoteIcon from "../images/common/quote-icon.svg"

const IndexPage = () => {


  const refFaq1 = useRef(null);
  const refFaq2 = useRef(null);
  const refFaq3 = useRef(null);
  const refFaq4 = useRef(null);
  const refFaq5 = useRef(null);

  const contactRef = useRef(null);


  function faqScrollTo(faqRef, hash){
    
    if (faqRef && faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth'});
      setTimeout(function(){
        document.location.hash = hash;
      }, 500); 
    }
  }

  function contactScrollTo(){
    
    if (contactRef && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth'});
      setTimeout(function(){
        document.location.hash = "#contact";
      }, 500); 
    }
  }


  return (
    <Layout>
       <section id="hero-section"> 
        
            <div className="container"><Navigation /></div>
            <div className="container">
                    <div className="columns hero-cols">
                      <div className="column is-6 hero-img-group">
                              <div className="tile hero-title-secondary">
                                <p>Concierge</p>
                              </div>
                              <div className="tile hero-title">
                                <p>Menopausal<br/> Service</p>
                              </div>
                          <StaticImage alt="Dr. Antoaneta Mueller, M.D., FACOG, Premier Board Certified Ob-Gyn - South Bay CA" loading="eager" quality="100" className="hero-img" imgStyle={{ objectFit: 'cover' }}  src="../images/home/hero-image@2x.png"></StaticImage>
                      </div>
                      <div className="column is-6 hero-desc-group">
                          <div className="tile is-ancestor is-vertical">
                              <div className="tile hero-title-secondary">
                                <p>Concierge</p>
                              </div>
                              <div className="tile hero-title">
                                <p>Menopausal<br/> Service</p>
                              </div>
                              <div className="tile name">
                              Dr. Antoaneta Mueller, M.D.
                              </div>
                              <div className="tile cert-title">
                              FACOG
                              </div>
                              <div className="tile cert-desc">
                              Premier Board Certified Ob-Gyn, having served in the<br/> Long Beach community since 2007.
                              </div>
                              <div className="tile book-btn" onClick={contactScrollTo}>
                                <div className="btn-container">
                                  <div className="btn-content">BOOK APPOINTMENT</div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
              </div>
       </section>

       <section id="quote-intro">
            <div className="container">
                    <div className="columns is-centered quote-cols">
                      <div className="column is-8 is-10-tablet quote-group">
                          <div className="tile is-ancestor is-vertical">
                                <div className="tile">
                                    <p className="quote-prim">WHY I STARTED</p>
                                </div>
                                <div className="tile">
                                    <p className="quote-secnd">Beyond My Menopause</p>
                                </div>
                                <div className="tile quote-desc">
                                    <img alt="Quote Icon" className="quote-icn" src={quoteIcon} />
                                    <p className="quote-para-offset"> Patients often come to me in tears as their day to day stressors have left them imbalanced preventing them from effectively managing their daily life. They have difficulty sleeping, report low energy and libido, poor memory and concentration and emotional lability.</p>
                                </div>
                                <div className="tile quote-desc">
                                    <p> Medical school and residency never prepared me for this challenge even though it affects all women eventually. Unhappy with conventional therapy,<br/> I threw myself into a more integrative approach.</p>
                                </div>
                                <div className="tile">
                                        <a href="https://beyondwomenshealth.com/about/" className="about-btn" >
                                            <div className="btn-container">
                                                <div className="btn-content">Meet Dr. Mueller</div>
                                            </div>
                                        </a>
                                </div>
                          </div>
                      </div>
                    </div>
            </div>
       </section>


       <section id="exp-intro">
            <div className="container">
                    <div className="columns is-centered exp-cols">
                      <div className="column is-8 is-10-tablet exp-group">
                          <div className="tile is-ancestor is-vertical">
                                <div className="tile exp-prim">
                                    <p>Having experienced</p>
                                    <div className="exp-highlight-cont"><span className="exp-highlight">similar symptoms myself</span><span className="highlight-border"></span></div>
                                    <p> I dove into menopause</p>
                                    <p> therapy and how best to </p>
                                    <p>manage these changes.</p>
                                </div>
                          </div>
                      </div>
                    </div>
            </div>
       </section>


       <section id="goal-intro">
            <div className="container">
                    <div className="columns is-centered goal-cols">
                      <div className="column is-8 is-10-tablet goal-group">
                          <div className="tile is-ancestor is-vertical">
                                <div className="tile goal-prim">
                                    <p>
                                        Whether you are perimenopausal or in menopause,<br/>
                                        my goal is to provide personalized therapy that will allow you<br/>
                                        to <b>regain your life back</b> and help you to feel balanced.
                                    </p>
                                </div>
                          </div>
                      </div>
                    </div>
            </div>
       </section>

       <section id="together-section">   
            <div className="container">
                    <div className="columns together-cols">
                      <div className="column is-6 together-img-group">
                          <div className="together-img-border">
                          <div className="together-img-wraper">
                          <StaticImage alt="A doctor providing care for her patient" loading="eager" quality="100" className="together-img" imgStyle={{ objectFit: 'cover' }}  src="../images/home/together-image@2x.png"></StaticImage>
                          </div>
                          </div>
                      </div>
                      <div className="column is-6 together-desc-group">
                          <div className="tile is-ancestor is-vertical">
                              <div className="tile together-title">
                                <p>We Will Reach <br/> Your Goals <span className="together-highlight">together</span></p>
                              </div>
                              <div className="tile together-desc">
                              Using an integrative approach that not only focuses on hormone therapy but also lifestyle changes.
                              </div>
                              <div className="tile book-btn">
                                <div className="btn-container" onClick={contactScrollTo}>
                                  <div className="btn-content">BOOK APPOINTMENT</div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
              </div>
       </section>



       <section id="faq-section">
            <div className="container">   
                <div className="faq-title-grp">
                        <div className="faq-title-prim">Frequently Asked Questions</div>
                        <div className="faq-title-secondary">Dr. Mueller explains menopause and treatment options.</div>
                </div>
            </div>
            <div className="container faq-container">
                    <div className="faq-quicklinks-container">
                            <div className="faq-quicklinks-title">JUMP TO A SECTION</div>
                            <div className="faq-quicklinks-grp">
                                <div href="#faq-what-is-menopause" className="faq-quicklink" onClick={()=> faqScrollTo(refFaq1,"#faq-what-is-menopause")}>Menopause</div>
                                <div href="#faq-what-is-perimenopause" className="faq-quicklink" onClick={()=> faqScrollTo(refFaq2,"#faq-what-is-perimenopause")}>Perimenopause</div>
                                <div href="#faq-what-is-happening-to-my-body" className="faq-quicklink" onClick={()=> faqScrollTo(refFaq3,"#faq-what-is-happening-to-my-body")}>Changes in body</div>
                                <div href="#faq-how-do-i-monitor-my-hormones" className="faq-quicklink" onClick={()=> faqScrollTo(refFaq4,"#faq-how-do-i-monitor-my-hormones")}>How to monitor hormones</div>
                                <div href="#faq-when-to-check-my-blood" className="faq-quicklink" onClick={()=> faqScrollTo(refFaq5,"#faq-when-to-check-my-blood")}>When to check blood?</div>
                            </div>
                    </div>
                    <div className="faq-queries-container">
                            <div className="faq-grp" id="faq-what-is-menopause" ref={refFaq1}>
                                    <div className="faq-question">What is menopause?</div>
                                    <div className="faq-answer">
                                      <div>
                                      <div className="faq-highlight-cont"><span className="faq-highlight"><b>Menopause</b></span><span className="highlight-border"></span></div>
                                        &nbsp;is defined as the end of monthly cycles signaling the end of fertility for women. The mean age is 51. Clinically, menopause is 12 months without a menstrual cycle.
                                      </div>
                                    </div>
                            </div>
                            <div className="faq-grp" id="faq-what-is-perimenopause" ref={refFaq2}>
                                    <div className="faq-question">What is perimenopause?</div>
                                    <div className="faq-answer">
                                      <p>
                                        Perimenopause is the time around menopause that begins on average&nbsp; 
                                        <div className="faq-highlight-cont"><span className="faq-highlight"><b> 4 years </b></span><span className="highlight-border"></span></div>
                                        &nbsp;before the final menstrual period.
                                      </p>
                                    </div>
                            </div>
                            <div className="faq-grp" id="faq-what-is-happening-to-my-body" ref={refFaq3}>
                                    <div className="faq-question">What is happening to my body?</div>
                                    <div className="faq-answer">
                                      <p>
                                        The complex balance of estrogen and progesterone in our body is shifting leading to a disruption in our normal physical and mental state leaving us to feel “unbalanced”.
                                      </p>
                                      <p className="faq-points-title">
                                        Such symptoms often include:
                                      </p>
                                      <ul className="faq-points">
                                        <li>Hot flashes and night sweats</li>
                                        <li>Vaginal dryness and painful intercourse</li>
                                        <li>Absent or irregular cycles</li>
                                        <li>Heavier cycles</li>
                                        <li>Skin changes</li>
                                        <li>Poor libido</li>
                                        <li>Depression</li>
                                        <li>Irritability and anxiety</li>
                                        <li>Fatigue</li>
                                        <li>Poor memory</li>
                                        <li>Increase in abdominal fat</li>
                                        <li>Insomnia</li>
                                        <li>Joint pain</li>
                                      </ul>  
                                    </div>
                            </div>
                            <div className="faq-grp" id="faq-how-do-i-monitor-my-hormones" ref={refFaq4}>
                                    <div className="faq-question">How do I monitor my hormones?</div>
                                    <div className="faq-answer">
                                      <p>
                                        Although we can get an idea of where the problem lies through careful assessment of history and symptoms, blood tests give us more information about exactly what is going on with each patient.
                                      </p>
                                      <p className="faq-points-title">
                                        Blood tests:
                                      </p>
                                      <ul className="faq-points">
                                        <li>Give a detailed analysis of specific hormones.</li>
                                        <li>Ensure an accurate diagnosis.</li>
                                        <li className="faq-point-long">Allows us to ensure the amount of hormones in your body are not higher than what is physiologically appropriate, and to know that the prescribed treatment is working effectively.</li>
                                        <li className="faq-point-long">Help us to understand if any changes to your medication are required.</li>
                                        <li>Are essential for monitoring your safety.</li>
                                      </ul>  
                                    </div>
                            </div>
                            <div className="faq-grp" id="faq-when-to-check-my-blood" ref={refFaq5}>
                                    <div className="faq-question">When to check my blood?</div>
                                    <div className="faq-answer">
                                      <p className="faq-points-title faq-points-title-secondary">
                                        Hormones are best drawn between 8-10 AM
                                      </p>
                                      <ul className="faq-points secondary-points">
                                        <li>For a topical hormone cream or gel applied once daily, testing should be done approximately 10-12 hours after the last application.</li>
                                        <li>If the topical cream or gel is used twice a day or every 12 hours, testing should be done approximately 6-8 hours after the last application.</li>
                                      </ul>  
                                      <p className="faq-points-title">
                                        When you first begin your journey with Beyond Menopause, we may conduct blood tests fairly regularly. But once hormone levels are stabilized, we move to more infrequent testing.
                                      </p>
                                    </div>
                            </div>
                    </div>
            </div>
        </section>


        <section id="service-section"> 
        
        <div className="container">
                <div className="columns service-cols">
                  <div className="column is-5-tablet is-4-desktop service-title">
                            <div>What To Expect From Our <br/><span className="service-title-highlight">Concierge</span><br/>Menopausal Service?</div>
                  </div>
                  <div className="column is-7-tablet is-8-desktop service-desc">
                      <div className="service-desc-paragraph">
                      In the initial appointment, we need to understand whether you are perimenopausal, menopausal, or have some other hormonal condition. The first step to achieving youthful hormone balance is to have a blood test panel that evaluates a woman’s individual needs.
                      Continual <div className="service-highlight-cont"><span className="service-highlight">assessment of hormone levels</span><span className="highlight-border"></span></div>&nbsp;is necessary for women seeking to maintain a healthy hormonal balance.
                      </div>
                      <div className="service-desc-paragraph">
                      If the <b>follicle stimulating hormone (FSH)</b> and <b>luteinizing hormones (LH)</b> are raised, this indicates&nbsp;
                       <div className="service-highlight-cont"><span className="service-highlight">perimenopause</span><span className="highlight-border"></span></div>
                       &nbsp;or&nbsp;
                      <div className="service-highlight-cont"><span className="service-highlight">menopause.</span><span className="highlight-border"></span></div>
                      If they are not raised, then this signifies that there could be another kind of hormonal imbalance. We also look at the baseline levels of the sex hormones: <b>estrogen, progesterone, testosterone and DHEA.</b> Once we establish baseline blood results, it enables us to understand the exact situation and correlate the blood results with symptoms, and this then facilitates selection of the best treatment option for you.
                      </div>
                  </div>
                </div>
          </div>
   </section>


   <section id="sample-report-section">
          <div className="container">

              <div className="sample-report-img-wrapper">
              <StaticImage alt="A Lab Technician Analyzing A Blood Sample" className="sample-report-img" src="../images/home/blood-test-image@2x.png" ></StaticImage>
              </div>

              <p className="sample-report-title">Sample Report</p>
              <p className="sample-report-title-secondary">Understanding Lab Results</p>

              <div className="columns point-row is-centered">
                  <div className="column is-8">
                      <div className="sample-report-grp">
                            <div className="point-number">01</div>
                            <div className="point-title">Estrogen and progesterone</div>
                            <p className="point-desc">For women who have monthly menstrual cycles, we check the blood hormone levels on day 21 of their cycle and analyze the estrogen-to-progesterone ratio. For longer or shorter cycles, count 8 days back from the day your next menstruation would start.</p>
                            <p className="point-desc">Ideally, the ratio should be 10:1. Day 21 progesterone ( in a 28 day cycle) is indicative of whether a woman is ovulating.</p>
                            <p className="point-desc">The pattern of hormone change varies from woman to woman, and in perimenopause, estrogen levels can spike and fluctuate.</p>
                      </div>
                  </div>
              </div>

              <div className="columns point-row is-centered">
                  <div className="column is-8">
                      <div className="sample-report-grp">
                            <div className="point-number">02</div>
                            <div className="point-title">Testosterone</div>
                            <p className="point-desc">In menopause, both estrogen and progesterone are low. When looking at testosterone, this is usually the last hormone to deplete. However, in some women, testosterone can decline before estrogen, and this can occur because there is decreased production of testosterone from the adrenal glands.</p>
                            <p className="point-desc"> We need to measure free testosterone because this tells us how much testosterone is bioavailable (available for the body to use).</p>
                      </div>
                  </div>

                  </div>
              
              <div className="columns point-row is-centered">
                  <div className="column is-8">
                      <div className="sample-report-grp">
                            <div className="point-number">03</div>
                            <div className="point-title">Dehydroepiandrosterone (DHEA)</div>
                            <p className="point-desc">DHEA is an adrenal hormone. It is a precursor to the sex hormones and gradually declines with age. Some women have a low DHEA if they have had chronic stress or trauma.</p>
                      </div>
                  </div>
              </div>
          </div>
       </section>

        

       <section id="ready-to-schedule">
            <div className="container">
                <img alt="Beyond Symbol" className="schedule-symbol" src={beyondSymbolWhite} />
                <p className="schedule-title">Still Have Questions?</p>
                <div className="tile is-ancestor columns is-centered">
                    <div className="tile schedule-desc column is-8-tablet is-6-desktop ">
                        <p>
                          Discover more about our services. Don’t hesitate to 
                         &nbsp;<div className="schedule-highlight-cont"><span className="schedule-highlight">book a consultation</span><span className="highlight-border"></span></div>
                         &nbsp;with Dr. Mueller to begin your menopause journey.
                         </p>
                    </div>
                </div>
                <div className="schedule-now-btn" onClick={contactScrollTo}>
                      <div className="btn-container">
                        <div className="btn-content">BOOK APPOINTMENT</div>
                      </div>
                </div>
            </div>
       </section>

       <section id="contact" ref={contactRef}>
          <div className="container">
              <div className="columns">

                
                  <div className="column is-6 contact-info-grp">
                      <div className="tile is-ancestor is-vertical">
                          <div className="tile contact-title">Our Offices</div>
                          <div className="tile contact-title-name">BEYOND WOMEN’S HEALTH</div>
                          <div className="tile contact-desc"><img alt="Location" className="contact-image" quality="100" src={locationIcon} />3655 Lomita Blvd Suite 321<br/> Torrance CA, 90505</div>
                          <a href="tel:310-325-1198"className="tile contact-number"><img alt="Call" className="contact-image" quality="100" src={phoneIcon} />(310) 325-1198</a>
                      </div>
                  </div>
                  <div className="column is-6 contact-map">
                      <StaticImage alt="Location Map for Beyond My Menopause" className="contact-map-image" quality="100" src="../images/common/location-map.png" />
                  </div>
              </div>
          </div>
       </section>
    </Layout>
  )
}

export default IndexPage
